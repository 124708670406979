import React from 'react';
import DataSetType from 'types/dataset.type';
import IconButton from 'components/ui-components-v2/IconButton';
import Icon from 'components/ui-components-v2/Icon';
import User from 'components/data/User';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Translation from 'components/data/Translation';

import '../styles/actions.scss';

interface Props {
    dataSet?: DataSetType;
    onPreviewClick: () => void;
    onEditClick: () => void;
    onDeleteClick: () => void;
}

const AddFeedSelectorActions: React.FC<Props> = ({ dataSet, onPreviewClick, onEditClick, onDeleteClick }) => {
    return (
        <div className="add-feed-selector-actions">
            {dataSet && User.hasRight('feedManagement') && (
                <Tooltip title={Translation.get('labels.preview')}>
                    <IconButton onClick={onPreviewClick}>
                        <Icon>preview</Icon>
                    </IconButton>
                </Tooltip>
            )}
            <Tooltip title={Translation.get('feeds.addForm.editFeed', 'feed-management')}>
                <IconButton onClick={onEditClick}>
                    <Icon>edit</Icon>
                </IconButton>
            </Tooltip>

            {dataSet && (
                <Tooltip title={Translation.get('actions.delete')}>
                    <IconButton onClick={onDeleteClick}>
                        <Icon>delete</Icon>
                    </IconButton>
                </Tooltip>
            )}
        </div>
    );
};

export default AddFeedSelectorActions;
