import React, { useEffect, useState } from 'react';
import DataSetType from 'types/dataset.type';
import isEqual from 'lodash/isEqual';
import AddFeedDialog from 'components/feed-management/AddFeedDialog';
import Typography from 'components/ui-components-v2/Typography';
import Link from 'components/ui-components-v2/Link';
import Icon from 'components/ui-components-v2/Icon';
import FeedRequest from 'components/data/FeedManagementHelpers/feed-request';
import DataSetPreviewDialog from 'components/feed-management/DataSetPreviewDialog';
import Translation from 'components/data/Translation';
import Tooltip from 'components/ui-components-v2/Tooltip';
import AddFeedSelectorActions from './actions';

import '../styles/main.scss';

interface FeedValue {
    datasetId: string;
    feedId: string;
}
interface Props {
    value?: FeedValue;
    onMutation: (value: unknown, type?: string) => void;
}

const AddFeedSelector: React.FC<Props> = ({ value, onMutation }) => {
    const [isAddFeedDialogOpen, setIsAddFeedDialogOpen] = useState(false);
    const [dataSet, setDataSet] = useState<DataSetType>();
    const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false);

    const loadDataSet = async (feedValue?: FeedValue) => {
        if (!feedValue || isEqual(dataSet, feedValue)) return;

        const data = (await FeedRequest.get(`dataset/${feedValue.datasetId}`)).data;
        setDataSet(data);
    };

    const getFeedTitle = () => {
        if (!dataSet || !dataSet.feeds.length) return Translation.get('addFeedSelector.noFeedYet', 'feed-management');
        return dataSet.feeds[0].name;
    };

    const getFeedUrlLabel = () => {
        if (!dataSet || !dataSet.feeds.length) return Translation.get('addFeedSelector.provideAFeedViaUrlOrFeedManagement', 'feed-management');
        return dataSet.feeds[0].url;
    };

    const handleOnSelect = (feedValue: FeedValue) => {
        loadDataSet(feedValue);
        onMutation(feedValue);
    };

    const handleOnDelete = () => {
        setDataSet(undefined);
        onMutation(undefined);
    };

    useEffect(() => {
        loadDataSet(value);
    }, [value]);

    return (
        <>
            <div className="add-feed-selector">
                <div className="add-feed-selector__icon">
                    <Icon>dataset</Icon>
                </div>
                <div className="add-feed-selector__details">
                    <Typography variant="body2" noWrap>
                        {getFeedTitle()}
                    </Typography>
                    <Tooltip title={dataSet?.feeds?.[0]?.url}>
                        <Link color="textSecondary" href={dataSet?.feeds?.[0]?.url} target="_blank" rel="noopener noreferrer" noWrap>
                            {getFeedUrlLabel()}
                        </Link>
                    </Tooltip>
                </div>
                <AddFeedSelectorActions
                    dataSet={dataSet}
                    onPreviewClick={() => setIsPreviewDialogOpen(true)}
                    onEditClick={() => setIsAddFeedDialogOpen(true)}
                    onDeleteClick={handleOnDelete}
                />
            </div>
            {isAddFeedDialogOpen && <AddFeedDialog onSelectFeed={handleOnSelect} onClose={() => setIsAddFeedDialogOpen(false)} />}
            {isPreviewDialogOpen && <DataSetPreviewDialog onClose={() => setIsPreviewDialogOpen(false)} dataSet={dataSet} />}
        </>
    );
};

export default AddFeedSelector;
